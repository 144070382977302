<template>
  <div class='safety-attendance-config mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <el-tabs v-model="tabsActive" type="border-card">
      <el-tab-pane label="时间配置" name="1">
        <div class="tabsContent">
          <div class="leftDiv">
            <div class="item" @click="confActive=item.dictValue" :class="{'active':confActive==item.dictValue}" v-for="(item,index) in dicObj.confData" :key="index">{{ item.dictName }}</div>
          </div>
          <div class="rightDiv">
            <el-form ref="dioform" :model="inform" label-width="100px" size="small">
              <el-form-item label="配置时间:">
                <div class="item" v-for="(item,index) in inform.weekArr" :key="index">
                  <div class="name">
                    {{item.weekDay=='1'?'星期日':item.weekDay=='2'?'星期一':item.weekDay=='3'?'星期二':item.weekDay=='4'?'星期三':item.weekDay=='5'?'星期四':item.weekDay=='6'?'星期五':'星期六'}}
                  </div>
                  <el-time-picker is-range v-model="item.value" format="HH:mm" value-format="HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @change="timeChange($event,item)"></el-time-picker>
                  <!-- <el-slider v-model="item.value" :step="1" :max="1440" :marks="marks" range :format-tooltip="formatTime2" @change="getTime($event,item)"></el-slider> -->
                </div>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" size="small" @click="saveFun()" v-if="isAuth('safe:safety:attendance:config:save')">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-attendance-config',
  data() {
    return {
      tabsActive:'1',//tabs选中

      confActive:'',//模板选中

      dicObj:{
        confData:[],//时间配置模板
      },

      inform:{
        weekArr: []
      },
      marks: {
        0: '00',
        120: '02',
        240: '04',
        360: '06',
        480: '08',
        600: '10',
        720: {
          style: {
            color: '#1989FA'
          },
          label: '12'
        },
        840: '14',
        960: '16',
        1080: '18',
        1200: '20',
        1320: '22',
        1440: {
          label:'24'
          // label: this.$createElement('strong', '24')
        }
      },

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    breadCrumb,
  },
  watch:{
    
  },
  async created(){
    vm = this
    vm.getCurrDics().then(()=>{
      vm.attendanceWeekConf()
    })
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted(){
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //保存
    saveFun(){
      this.attendanceWeekConfUpdata()
    },
    //更新时间配置
    async attendanceWeekConfUpdata(){
      let arr = []
      for(var i in vm.inform.weekArr){
        let obj = {
          'id': vm.inform.weekArr[i].id,
          'startTime':vm.inform.weekArr[i].value?.length>0 ? vm.inform.weekArr[i].value[0]:'',
          'endTime':vm.inform.weekArr[i].value?.length>0 ? vm.inform.weekArr[i].value[1]:''
        }
        arr.push(obj)
      }
      // let dataObj = {
      //   'data':arr
      // }
      const res = await apiUrl.attendanceWeekConfUpdata(arr)
      if(res?.code==200){
        vm.$message.success('修改成功')
        vm.attendanceWeekConf()
      }else{
        vm.$message.error(res.message)
      }
    },
    timeChange(val,item){
      if(val){
        if( val[0]===val[1]) {
          this.$message.warning('开始时间和结束时间不能相同,请重新设置')
          item.value = ''
          return
        }
        let timeParts1 = val[0].split(':');  
        let timeParts2 = val[1].split(':');  
        timeParts1[2] = '00'; // 强制秒数为 00  
        timeParts2[2] = '00'; // 强制秒数为 00  
        let newTime = [timeParts1.join(':'),timeParts2.join(':')]
        item.value = newTime
      }
      
    },
    //获取列表
    async attendanceWeekConf (){
      let dataObj = {
        'confType':vm.confActive,
      }
      const res = await apiUrl.attendanceWeekConf(qs.stringify(dataObj))
      if(res?.code==200){
        for(var i in res.data){
          if(res.data[i].startTime &&res.data[i].endTime) {
            res.data[i].value = [res.data[i].startTime,res.data[i].endTime]
          }else {
            res.data[i].value = ''
          }
        }
        vm.inform.weekArr = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics(){
      let dataObj = ['DOMAIN_ATTENDANCE_CONF_TYPE']
      const res = await apiUrl.getDicts(dataObj)
      if(res?.code==200){
        vm.dicObj.confData = res.data.DOMAIN_ATTENDANCE_CONF_TYPE||[];//考勤时间配置模板
        vm.confActive = res.data.DOMAIN_ATTENDANCE_CONF_TYPE[0]?.dictValue
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safety-attendance-config{
  .tabsContent{
    display: flex;
    color: #333;
    height: calc(100vh - 172px);
    .leftDiv{
      width: 20%;
      flex-shrink: 0;
      border-right: 1px solid #1d4975;
      padding: 10px;
      box-sizing: border-box;
      .item{
        padding: 5px 10px;
        border-radius: 6px;
        border-bottom: 1px solid #1d4975;
        cursor: pointer;
        &.active{
          background: #409eff;
          color: #fff;
        }
      }
    }
    .rightDiv{
      width: 70%;
      color: #fff;
      // flex-grow: 1;
      .el-form-item{
        .el-form-item__label{
          color: #fff;
        }
        .el-input__inner{
          background-color: #031e44;
          border-color: #1d4975;
          color: #00c6ff;
        }
        .el-date-editor .el-range-input{
          background: none;
          color: #00c6ff;
        }
        .item{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .name{
            margin-right: 20px;
            flex-shrink: 0;
          }
          .el-slider{
            flex-grow: 1;
            .el-slider__marks-text{
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
